.list-option-chat {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 204px;
  height: 44px;
  padding: 8px;
  background-color: #eef0f4;

  .btn-agree-selected {
    width: 60px;
    height: 28px;
    border-radius: 24px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
  }

  &-select {
    width: 120px;
    height: 28px;

    .ant-select-selector {
      border-radius: 4px;
    }

    &__value {
      border-radius: 0 0 8px 8px;

      .ant-select-item-option {
        padding: 6px 12px;
        padding-right: 4px;
        width: 120px;
        height: 28px;
        border-radius: 0 0 4px 4px;

        &-state {
          svg {
            width: 20px;
            height: 20px;
          }
        }

        &-disabled,
        &-disabled:hover {
          background: #dcdcdc !important;
        }
      }
    }
  }
}

.option-status-chat {
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  span {
    &:nth-child(2) {
      padding-top: 2px;
      font-size: 10px;
      font-weight: 600;
      line-height: 16px;
    }
  }

  &.open {
    color: #ffb021;
  }

  &.in-progress {
    color: #ff3b30;
  }

  &.closed {
    color: #1c86ed;
  }
}
