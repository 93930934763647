.ant-custom-table {
  .ant-table-thead {
    border-bottom: 2px solid #adadad !important;
    background: #eef0f4;

    .ant-table-cell {
      height: 48px;
      padding: 0 12px;
      border-bottom: 2px solid #adadad !important;
      background-color: #eef0f4;
      border-radius: unset !important;
      color: #514b4d;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      color: #514b4d;
    }
  }

  .odd-row {
    background-color: #f7f7f7;
  }
}
