// aside.ant-layout-sider.ant-layout-sider-dark.layout-sider-default {
//   display: none;
// }

h6,
p {
  margin-bottom: 0 !important;
}

.item-detail {
  position: relative;

  &__text-area-container {
    height: 160px !important;
  }

  &__percent-diff {
    width: 384px !important;

    &-title {
      display: flex;
      gap: 15px;
      width: 300px;
      margin: 0 auto 24px auto;

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        margin-bottom: 0;
        color: #514b4d;
      }
    }
  }

  &__confirm-disposal {
    width: 384px !important;

    .ant-modal-content {
      height: 140px;
      border-radius: 8px;
      padding: 24px 32px;
    }

    &-title {
      display: flex;
      gap: 15px;
      width: 196px;
      margin: 0 auto 24px auto;

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        margin-bottom: 0;
        color: #514b4d;
      }
    }
  }

  &__inputed {
    &-button {
      &-container {
        display: flex;
        gap: 24px;
        justify-content: end;
      }

      &-action {
        width: 148px !important;
        height: 44px;
      }
    }

    &-modal-container {
      width: 630px !important;

      .ant-modal-content {
        height: 270px;
        padding: 40px 43px;
        border-radius: 20px;
      }
    }

    &-input {
      &-label {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #514b4d;
        display: flex;
        gap: 8px;

        span {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: #f43258;
        }
      }

      &-price {
        margin-bottom: 60px !important;

        .ant-form-item-label {
          margin-left: 25px !important;

          .ant-form-item-required {
            &:before {
              content: unset !important;
            }
          }
        }

        .ant-input-affix-wrapper {
          border: unset !important;
          padding: unset !important;
          box-shadow: unset !important;
          justify-content: space-between;

          &:before {
            content: unset !important;
          }

          .ant-input-prefix {
            margin-inline-end: 10px !important;
          }

          .ant-input {
            width: 518px;
            height: 54px;
            border: 1px solid #adadad;
            border-radius: 8px;
            padding: 15px 16px;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #514b4d;

            &:focus {
              border-color: #1677ff !important;
              box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1) !important;
              outline: 0;
            }

            &:hover {
              border-color: #4096ff !important;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }

          input[type='number'] {
            -moz-appearance: textfield;
          }
        }

        .ant-input-status-error {
          .ant-input {
            border: 1px solid #ff4d4f;

            &:focus {
              border-color: #ff4d4f !important;
              box-shadow: 0 0 0 2px rgba(255, 38, 5, 0.06) !important;
              outline: 0;
            }

            &:hover {
              border-color: #ffa39e !important;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
    }
  }

  &__record-require {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #f43258;
    margin-left: 20px;
  }

  &__require {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #f43258;
  }

  &__tracking-number {
    word-break: break-all;
  }

  &__video-container {
    position: relative;
    border-radius: 8px;

    button {
      position: absolute;
      padding: 0;
      top: -8px;
      right: -8px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border: 1px solid transparent;
      border-radius: 100%;
      transition: all 0.3s linear;
      box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.12);

      &:hover {
        border: 0.5px solid #77b6f4;
      }
    }
  }

  &_back {
    z-index: 100;
    position: absolute;
    top: 0;
    left: -36px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #fff;
    border-radius: 100%;
    z-index: 2;
    transition: all 0.3s linear;
    filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.12));

    svg {
      path {
        transition: all 0.3s linear;
      }
    }

    &:hover {
      background: #1c86ed;
    }

    &:hover path {
      fill: #fff;
    }
  }

  &_container {
    width: 1640px;
    margin: auto;
    display: flex;
    align-items: start;
    justify-content: center;
    border-radius: 20px;
    background: #fff;
    padding: 24px 40px;

    @media screen and (max-width: 1639px) {
      width: unset;
    }
  }

  &_content {
    width: 855px;
    margin-right: 16px;
    position: relative;

    @media screen and (max-width: 1639px) {
      flex-shrink: 0;
    }

    &_name-info {
      h6 {
        color: #231f20;

        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        /* 142.857% */
      }

      &_wrap {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-top: 4px;

        p {
          color: #7e7e7e;

          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          /* 150% */
        }
      }
    }

    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2px;

      &_title {
        color: #ffb021;
        text-align: center;

        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        /* 150% */
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 84px;
        height: 32px;
        padding: 5px 16px;
        border-radius: 24px;
        background: rgba(247, 176, 47, 0.16);

        &.error {
          color: #f43258;
          background: #ffe0e0;
        }
      }

      &_button-wrap {
        display: flex;
        align-items: center;
        gap: 16px;

        &_disposal-request {
          color: #231f20;
          text-align: center;

          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          /* 150% */
          display: flex;
          width: 102px;
          height: 32px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          border: 2px solid #7e7e7e;
          background: #dcdcdc;
          padding: 0;
          transition: all 0.3s linear;

          &:hover {
            background: #f2f2f2;
          }
        }

        &_shipping-request {
          color: #fff;
          text-align: center;

          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          /* 150% */
          display: flex;
          width: 102px;
          height: 32px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: #1c86ed;
          border: none;
          padding: 0;
          transition: all 0.3s linear;

          &:hover {
            background: #77b6f4;
          }
        }

        &_return-print {
          display: flex;
          width: 102px;
          height: 32px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          border: 2px solid #1c86ed;
          background: #fff;
          padding: 0;
          transition: all 0.3s linear;

          &:hover {
            background: #1c86ed;
          }

          &:hover p {
            color: #fff;
          }

          &:hover path {
            stroke: #fff;
          }

          p {
            color: #1c86ed;
            text-align: center;

            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            /* 150% */
            margin-bottom: 0;
            transition: all 0.3s linear;
          }

          svg {
            path {
              transition: all 0.3s linear;
            }
          }
        }
      }
    }
    &_info-product {
      margin-top: 8px;
      padding-bottom: 28px;
      border-bottom: 1px solid #7e7e7e;

      &-img {
        width: 280px;
        height: 280px;
        border-radius: 4px;
        margin-bottom: 32px;

        &.for-operation-img {
          width: 180px;
          height: 180px;
          margin-bottom: 0;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 4px;
        }
      }

      &_title {
        color: #514b4d;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        /* 160% */
      }

      &_table {
        display: flex;
        align-items: start;
        gap: 16px;

        &.for-operation-table {
          gap: 24px;
        }

        .list {
          margin-top: 16px;

          &.for-operation-list {
            margin-top: 0;
          }
        }

        .title {
          position: relative;
          margin-bottom: 8px;

          span {
            color: #514b4d;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            margin-left: 8px;
          }

          &::after {
            content: '';
            position: absolute;
            width: 2px;
            height: 20px;
            background-color: #514b4d;
            left: 0;
          }
        }

        &_left {
          width: 306px;
          flex-shrink: 0;

          &.for-operation-left {
            width: 180px;
          }

          &_row {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 8px;
            padding: 0 4px;

            &:last-child {
              margin-bottom: 0;
            }

            &_left {
              width: 141px;
              flex-shrink: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;

              p {
                color: #7e7e7e;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                /* 157.143% */
              }

              span {
                color: #514b4d;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                /* 150% */
              }
            }

            &_right {
              display: flex;
              // justify-content: space-between;
              align-items: center;
              color: #514b4d;
              flex: 1;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
              gap: 8px;
              /* 150% */
              line-break: anywhere;

              &.underline {
                text-decoration: underline;

                a {
                  color: #514b4d;
                  font-weight: 600;
                }
              }

              &.remove-flex {
                flex: unset;
              }

              .ant-form-item {
                margin-bottom: 0;
              }

              &_formTracking {
                .ant-form-item {
                  margin: unset;

                  .ant-form-item-control-input {
                    min-height: 24px;
                  }

                  input {
                    max-width: 166px;
                    height: 24px;
                    max-height: 24px;
                    padding: 8px;
                    border-radius: 4px;
                    border: 1px solid #1c86ed;
                    background-color: transparent;
                    font-size: 16px;
                    font-weight: 600;
                  }
                }
              }

              .group-action {
                margin-left: 8px;
                width: 52px;
                display: flex;
                justify-content: space-between;

                span {
                  cursor: pointer;
                }
              }
            }

            &_editTracking {
              cursor: pointer;
            }
          }
        }

        &_right {
          flex-grow: 1;

          &_row {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 8px;
            padding: 0 4px;

            &:last-child {
              margin-bottom: 0;
            }

            &_item {
              display: flex;
              flex: 1;
              align-items: center;
              gap: 8px;

              &:nth-child(1) {
                min-width: 185px;
              }

              &:nth-child(2) {
                margin-left: 15px;

                .item-detail_content_info-product_table_right_row_left {
                  width: 126px !important;
                }
              }
            }

            &_left {
              width: 140px;
              flex-shrink: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;

              &_text {
                color: #7e7e7e;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                /* 157.143% */
                display: flex;
                align-items: center;
                gap: 4px;

                &_des {
                  position: relative;

                  svg {
                    cursor: pointer;
                  }

                  &_popup {
                    position: absolute;
                    top: calc(100% + 12px);
                    left: 50%;
                    transform: translateX(-50%);
                    padding: 8px 16px;
                    width: 236px;
                    border-radius: 10px;
                    background: #fff;
                    box-shadow:
                      0px 0px 1px 0px rgba(23, 15, 73, 0.05),
                      0px 1px 1px 0px rgba(23, 15, 73, 0.08),
                      0px 5px 14px 0px rgba(8, 15, 52, 0.08);
                    color: #514b4d;

                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    /* 150% */
                    opacity: 0;
                    pointer-events: none;
                    z-index: 2;
                    transition: all 0.3s linear;

                    &::before {
                      content: '';
                      position: absolute;
                      bottom: 100%;
                      left: 50%;
                      transform: translateX(-50%);
                      width: 0;
                      height: 0;
                      border-left: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-bottom: 15px solid rgba(237, 237, 237, 0.9);
                    }

                    &.show {
                      opacity: 1;
                      pointer-events: auto;
                    }
                  }
                }
              }

              p {
                color: #7e7e7e;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                /* 157.143% */
                display: flex;
                align-items: center;
                gap: 4px;
              }

              span {
                color: #514b4d;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                /* 150% */
              }
            }

            &_right {
              color: #514b4d;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;

              /* 150% */
              &.underline {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    &_note {
      padding-top: 35px;

      &_title {
        color: #514b4d;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      textarea {
        width: 100%;
        height: 160px;
        padding: 16px;
        border-radius: 8px;
        background: #f2f2f2;
        border: none;
        outline: none;
        margin-top: 8px;
        color: #514b4d;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;

        /* 157.143% */
        &::placeholder {
          color: #7e7e7e;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          /* 157.143% */
        }
      }
    }

    &_photo {
      padding-top: 27px;
      padding-bottom: 24px;
      border-bottom: 1px solid #7e7e7e;
      margin-left: 32px;
      margin-right: 32px;

      &_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h6 {
          color: #514b4d;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          /* 160% */
        }

        button {
          display: flex;
          height: 32px;
          width: 156px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 4px;
          border: 1px solid #1c86ed;
          background: #fff;
          transition: all 0.3s linear;

          &:hover {
            background: #1c86ed;
          }

          &:hover p {
            color: #fff;
          }

          &:hover path {
            stroke: #fff;
          }

          &.disable {
            border: 2px solid #7e7e7e;
            cursor: unset;

            p {
              color: #7e7e7e;
            }

            path {
              stroke: #7e7e7e;
            }

            &:hover {
              background: #fff;
            }
          }

          p {
            color: #1c86ed;
            text-align: center;

            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            /* 150% */
            transition: all 0.3s linear;
          }

          svg {
            path {
              transition: all 0.3s linear;
            }
          }
        }
      }

      &_list {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
        margin-top: 16px;

        &_item {
          width: 84px;
          height: 84px;
          border-radius: 8px;
          border: 1px solid #dcdcdc;
          position: relative;
          overflow: hidden;
          transition: all 0.3s linear;

          .remove-item {
            display: none;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.3s linear;
          }

          &_download {
            position: absolute;
            padding: 0;
            bottom: -35px;
            right: 8px;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border: none;
            border-radius: 100%;
            transition: all 0.3s linear;
            box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.12);
          }

          &_remove {
            position: absolute;
            padding: 0;
            top: -8px;
            right: -8px;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border: none;
            border-radius: 100%;
            box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.12);

            &:hover {
              border: 0.5px solid #77b6f4;
            }
          }

          &:hover {
            border: 2px solid #77b6f4;
            background: lightgray 50% / cover no-repeat;
            cursor: pointer;

            .btn-download-single {
              bottom: 8px;
            }
          }

          .ant-image-mask {
            &:hover {
              opacity: 0;
            }
          }
        }

        &_item:hover {
          .remove-item {
            display: block !important;
          }
        }
      }

      &_table {
        padding: 0 12px;
        margin-top: 16px;

        &_title {
          color: #514b4d;

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          /* 157.143% */
        }

        &_wrap {
          width: 100%;
          overflow: auto;
          margin-top: 8px;

          &_status {
            &_REQUESTED {
              color: #f43258 !important;
            }

            &_TAKE_BY_OPERATION {
              color: #f43258 !important;
            }

            &_UPLOAD_BY_STAFF {
            }
          }
        }

        table {
          width: 100%;

          tr {
            width: 100%;

            th {
              border-bottom: 1.5px solid #adadad;
              background: #eef0f4;
              height: 40px;
              padding: 0;

              p {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                color: #514b4d;
                text-overflow: ellipsis;
                /* MEDIUM/12 */

                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                /* 150% */
                padding: 0 12px;
              }
            }

            td {
              border-bottom: 1.5px solid #dcdcdc;
              padding: 0;
              padding: 8px 12px;

              p {
                color: #514b4d;
                cursor: pointer;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
              }
            }

            th:nth-child(1),
            td:nth-child(1) {
              width: 20%;
            }

            th:nth-child(2),
            td:nth-child(2) {
              width: 15%;
            }

            th:nth-child(3),
            td:nth-child(3) {
              width: auto;
            }

            th:nth-child(4),
            td:nth-child(4) {
              width: 20%;
              word-break: keep-all;
            }

            td:nth-child(4) p {
              text-decoration: underline;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
            }
          }
        }

        button {
          color: #1c86ed;
          text-align: center;

          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          /* 160% */
          margin-left: 6px;
          margin-top: 9px;
          background: transparent;
          border: none;
        }
      }
    }

    &_video {
      padding-top: 32px;
      padding-bottom: 24px;
      border-bottom: 1px solid #7e7e7e;
      padding-left: 32px;
      padding-right: 32px;

      &_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h6 {
          color: #514b4d;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          /* 160% */
        }

        button {
          display: flex;
          height: 32px;
          width: 156px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 4px;
          border: 1px solid #1c86ed;
          background: #fff;
          transition: all 0.3s linear;

          &:hover {
            background: #1c86ed;
          }

          &:hover p {
            color: #fff;
          }

          &:hover path {
            stroke: #fff;
          }

          &.disable {
            border: 2px solid #7e7e7e;
            cursor: unset;

            p {
              color: #7e7e7e;
            }

            path {
              stroke: #7e7e7e;
            }

            &:hover {
              background: #fff;
            }
          }

          p {
            color: #1c86ed;
            text-align: center;

            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            /* 150% */
            transition: all 0.3s linear;
          }

          svg {
            path {
              transition: all 0.3s linear;
            }
          }
        }
      }

      &_list {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
        margin-top: 16px;

        &_item {
          width: 84px;
          height: 84px;
          border-radius: 8px;
          border: 1px solid #dcdcdc;
          position: relative;
          transition: all 0.3s linear;
          overflow: hidden;
          display: flex;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &_download {
            position: absolute;
            padding: 0;
            bottom: -35px;
            right: 8px;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border: none;
            border-radius: 100%;
            transition: all 0.3s linear;
            box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.12);
          }

          &_remove {
            position: absolute;
            padding: 0;
            top: -8px;
            right: -8px;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border: none;
            border-radius: 100%;
            box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.12);

            &:hover {
              border: 0.5px solid #77b6f4;
            }
          }

          &:hover {
            border: 2px solid #77b6f4;
            background: lightgray 50% / cover no-repeat;
            cursor: pointer;

            .btn-download-single {
              bottom: 8px;
            }
          }

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    &_manager-comment {
      padding-top: 30px;
      position: relative;

      .loading {
        position: absolute;
        background-color: #fff;
        opacity: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50%;
        bottom: 0;
      }

      &_btn-group {
        margin-top: 4px;
        background: #f2f2f2;
        width: 64px;
        height: 28px;
        padding: 4px;
        gap: 16px;
        border-radius: 4px;
        display: flex;

        button {
          margin: unset;
          padding: unset;
          width: 20px;
          height: 20px;
        }
      }

      &_title {
        color: #514b4d;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 150% */
      }

      textarea {
        // height: unset !important;
        // width: 100%;
        // min-height: 54px;
        min-height: 120px;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid #adadad;
        outline: none;
        margin-top: 8px;
        color: #514b4d !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;

        &:hover,
        &:active,
        &:focus {
          background: #ffffff !important;
          box-shadow: unset !important;
        }

        &:disabled {
          background: #f2f2f2 !important;
          border: none;
          cursor: context-menu;
        }

        &::placeholder {
          color: #7e7e7e;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          /* 157.143% */
        }

        &::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }

        &::-webkit-scrollbar-corner,
        &::-webkit-scrollbar-track {
          background-color: #dcdcdc;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #adadad;
          background-clip: padding-box;
          border: 2px solid transparent;
          border-radius: 12px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: rgb(112, 112, 112);
        }

        &::-webkit-scrollbar-thumb:active {
          background-color: rgb(128, 128, 128);
        }

        /* Buttons */
        &::-webkit-scrollbar-button:single-button {
          background-color: #dcdcdc;
          display: block;
          background-size: 8px;
          background-repeat: no-repeat;
        }

        /* Up */
        &::-webkit-scrollbar-button:single-button:vertical:decrement {
          height: 10px;
          width: 10px;
          background-position: center 4px;
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
          border-top-right-radius: 8px;
        }

        &::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");
        }

        &::-webkit-scrollbar-button:single-button:vertical:decrement:active {
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='50,00 0,50 100,50'/></svg>");
        }

        /* Down */
        &::-webkit-scrollbar-button:single-button:vertical:increment {
          height: 10px;
          width: 10px;
          background-position: center 2px;
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
          border-bottom-right-radius: 8px;
        }

        &::-webkit-scrollbar-button:single-button:vertical:increment:hover {
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
        }

        ::-webkit-scrollbar-button:single-button:vertical:increment:active {
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 100,0 50,50'/></svg>");
        }

        /* Left */
        &::-webkit-scrollbar-button:single-button:horizontal:decrement {
          height: 7px;
          width: 14px;
          background-position: 5px 1px;
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>");
        }

        &::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
        }

        &::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
        }

        /* Right */
        &::-webkit-scrollbar-button:single-button:horizontal:increment {
          height: 7px;
          width: 17px;
          background-position: 5px 1px;
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
        }

        &::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>");
        }

        &::-webkit-scrollbar-button:single-button:horizontal:increment:active {
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
        }
      }
    }

    &_param {
      padding: 26px 0px;

      &_size {
        margin-top: 32px;
        position: relative;

        &:first-child {
          margin-top: 0;
        }

        &_title {
          color: #514b4d;

          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          /* 150% */
        }

        &_list {
          display: flex;
          align-items: center;
          gap: 32px;
          margin-top: 8px;

          &_item {
            display: flex;
            align-items: center;
            gap: 8px;
            width: 120px;
            flex-shrink: 0;

            label {
              color: #514b4d;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
              width: 47px;
            }

            input {
              display: flex;
              width: 60px;
              height: 40px;
              padding: 8px 14px 10px 16px;
              justify-content: center;
              align-items: center;

              font-size: 16px;
              color: #514b4d;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              /* 150% */
              border-radius: 8px;
              background: #fff;
              outline: none;
              border: 1px solid #adadad;

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }

            input[type='number'] {
              -moz-appearance: textfield;
            }

            input:disabled {
              border: none;
              background: #f2f2f2;
              color: #7e7e7e;
            }

            .value {
              display: flex;
              align-items: center;
            }

            .number {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              color: #514b4d;
            }

            .unit,
            .colon {
              font-size: 14px;
              font-weight: 500;
              line-height: 22px;
              color: #514b4d;
            }

            .colon {
              margin-right: 8px;
            }
          }
        }

        .edit-dimension-storage {
          position: absolute;
          top: 0;
          right: 0;

          &-btn {
            background: unset;

            svg {
              width: 32px;
              height: 32px;
              box-shadow: 1px 1px 8px 0px #0000001f;
              border-radius: 50%;

              rect {
                stroke: #fff;
              }
            }

            &:hover {
              svg {
                fill: #1c86ed;

                path {
                  stroke: #fff;
                }
              }
            }

            &-group {
              display: flex;
              flex-direction: column;
              gap: 10px;

              button {
                font-size: 12px;
                font-weight: 600;
                width: 102px;
                height: 32px;
              }

              .btn-submit {
                position: relative;

                .loading {
                  width: 100%;
                  background-color: #fff;
                  width: 102px;
                  height: 32px;
                  opacity: 0.5;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  top: 0;
                  position: absolute;
                  cursor: not-allowed;
                }
              }
            }
          }
        }
      }

      &_weight {
        &-container {
          display: flex;
          align-items: end;
          gap: 181px;
        }

        &-action {
          width: 102px;
          height: 32px;
          padding: 12px 16px 12px 16px;
          gap: 8px;
          border-radius: 8px;
          border: 2px solid #1c86ed;

          span {
            color: #1c86ed;
            transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          }

          &:hover {
            border: 2px solid #77b6f4 !important;

            span {
              color: #77b6f4;
            }
          }

          &:disabled {
            border: 2px solid #7e7e7e !important;

            span {
              color: #7e7e7e;
            }

            &:hover {
              border: 2px solid #7e7e7e !important;

              span {
                color: #7e7e7e;
              }
            }
          }
        }

        &_title {
          color: #514b4d;

          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          /* 150% */
          margin-top: 16px;
        }

        &_item {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-top: 8px;

          input {
            display: flex;
            width: 108px;
            height: 40px;
            padding: 8px 14px 10px 16px;
            justify-content: center;
            align-items: center;
            color: #514b4d;

            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
            border-radius: 8px;
            background: #fff;
            border: 1px solid #adadad;
            outline: none;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }

          input[type='number'] {
            -moz-appearance: textfield;
          }

          input:disabled {
            color: #7e7e7e;
            border: none;
            background: #f2f2f2;
          }
        }
      }
    }
  }

  &_right {
    flex-shrink: 0;
    overflow: hidden;
    width: 690px;

    @media screen and (max-width: 1639px) {
      flex-shrink: unset;
      width: unset;
    }
  }

  &_feedback {
    border: 1px solid #dcdcdc;
    margin-bottom: 32px;

    &_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 16px;
      background: #f7f7f7;

      &-left {
        display: flex;
        align-items: center;
        gap: 24px;
      }

      h6 {
        color: #514b4d;

        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 150% */
      }

      button {
        color: #1c86ed;
        text-align: center;
        font-style: normal;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        display: flex;
        height: 32px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #1c86ed;
        background: #fff;
        transition: all 0.3s linear;

        &:hover {
          background: #1c86ed;
          color: #fff;
        }

        &.btn-add {
          border-radius: 24px;
          width: 76px;
          background-color: #1c86ed;
          color: #fff;

          &:hover {
            opacity: 0.6;
          }
        }

        &.btn-expand {
          width: 160px;
          border-radius: 4px;
        }
      }
    }

    &_content {
      padding-right: 8px;
      padding-left: 16px;
      max-height: 230px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      width: 100%;

      &_item {
        display: flex;
        align-items: start;
        border-bottom: 1px solid #dcdcdc;
        padding: 16px 4px;
        width: 100%;

        &_text {
          position: relative;
          width: max-content;
        }

        &:last-child {
          border-bottom: unset;
        }

        &.add-bg {
          background-color: #e9f3fe;
        }

        &_img {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
          margin-right: 8px;
          background-color: #fff;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100%;
          }
        }

        .wp-content {
          width: 100%;

          .text-note {
            margin-top: 4px;
            width: 91%;

            @media screen and (max-width: 1639px) {
              width: 80%;
            }

            &.text-break-line {
              white-space: pre-wrap;
              word-wrap: break-word;
              overflow-wrap: break-word;
            }

            &.text-line {
              overflow: hidden;
              // display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              // white-space: nowrap;
              text-overflow: ellipsis;
              height: 20px;
            }
          }
        }

        &_text {
          display: flex;
          align-items: center;
          gap: 12px;
          h6 {
            color: #514b4d;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            /* 157.143% */
          }

          span {
            color: #7e7e7e;

            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            /* 160% */
          }

          p {
            color: #514b4d;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            /* 150% */
            margin-top: 10px;
            word-break: break-word;
          }
        }

        button {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;
          border: none;
        }
      }
    }
  }

  &_cancel-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s linear;
    z-index: 10;

    &.open {
      opacity: 1;
      pointer-events: auto;
    }

    &_overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(41, 55, 72, 0.6);
      z-index: 10;
    }

    &_body {
      padding: 24px 32px;
      border-radius: 8px;
      border: 1px solid #f7f7f7;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      position: relative;
      z-index: 11;

      &_text {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        margin-bottom: 24px;

        p {
          color: #514b4d;

          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          /* 150% */
        }
      }

      &_button {
        display: flex;
        align-items: center;
        gap: 24px;
      }
    }
  }

  &_add-photo {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s linear;
    z-index: 10;

    &.open {
      opacity: 1;
      pointer-events: auto;
    }

    &_overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(41, 55, 72, 0.6);
      z-index: 10;
    }

    &_body {
      max-width: 912px;
      width: 100%;
      padding: 40px;
      border-radius: 20px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      position: relative;
      z-index: 11;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: #adadad;
        border-radius: 12px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #dcdcdc;
      }

      &_top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h6 {
          color: #231f20;

          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px;
          /* 150% */
        }

        span {
          text-align: center;
          /* SEMIBOLD/12 */

          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          /* 150% */
          display: flex;
          // width: 84px;
          min-width: 84px;
          padding: 5px 16px;
          justify-content: center;
          align-items: center;
          border-radius: 24px;
        }

        &_REQUESTED {
          color: #f43258 !important;
          background-color: #ffe0e0;
        }

        &_TAKE_BY_OPERATION {
          color: #f43258 !important;
          background-color: #ffe0e0;
        }

        &_UPLOAD_BY_STAFF {
          color: #1c86ed;
          background-color: #d2e7fb;
        }
      }

      &_quantity {
        margin-top: 32px;

        label {
          color: #514b4d;

          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          /* 150% */
          margin-bottom: 8px;
        }

        input {
          display: flex;
          width: 100%;
          height: 54px;
          padding: 15px 16px;
          align-items: center;
          border-radius: 8px;
          background: #f2f2f2;
          color: #7e7e7e;

          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          /* 150% */
          border: none;
          outline: none;
        }
      }

      &_note {
        margin-top: 24px;

        label {
          color: #514b4d;

          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          /* 150% */
          margin-bottom: 8px;
        }

        textarea {
          display: flex;
          width: 100%;
          height: 208px;
          padding: 16px;
          align-items: center;
          border-radius: 8px;
          background: #f2f2f2;
          color: #7e7e7e;

          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          /* 150% */
          border: none;
          outline: none;
        }

        button {
          width: 24px;
          height: 24px;
          background: transparent;
          border: none;
        }

        &_translate {
          &_button {
            margin-top: 8px;
          }

          &_message {
            margin-top: 4px;
          }
        }
      }

      &_wrap {
        margin-top: 24px;

        &_title {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            color: #514b4d;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 160% */
          }

          button {
            display: flex;
            height: 32px;
            width: 116px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 4px;
            border: 1px solid #1c86ed;
            background: #fff;
            transition: all 0.3s linear;

            &:hover {
              background: #1c86ed;
            }

            &:hover p {
              color: #fff;
            }

            &:hover path {
              stroke: #fff;
            }

            &:disabled {
              border: 2px solid #7e7e7e;
              cursor: unset;

              p {
                color: #7e7e7e;
              }

              path {
                stroke: #7e7e7e;
              }

              &:hover {
                background: #fff;
              }
            }

            p {
              color: #1c86ed;
              text-align: center;

              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 18px;
              /* 150% */
              transition: all 0.3s linear;
            }

            svg {
              path {
                transition: all 0.3s linear;
              }
            }
          }
        }

        &_button-upfile {
          position: relative;
          margin-top: 16px;

          &_span {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 85px;
            opacity: 0;
            cursor: pointer;
          }

          div {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            border: 1px dashed #adadad;
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        .ant-upload-drag {
          background: #ffffff;

          p {
            font-size: 6px;
            font-weight: 500;
          }

          &.ant-upload-drag-hover {
            border: 2px solid #499ef1;
          }
        }

        .ant-form-item {
          .upload-modal {
            .ant-upload-btn {
              height: 85px;
            }
          }
        }
      }

      &_list {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
        margin-top: 16px;

        &_item {
          width: 84px;
          height: 84px;
          border-radius: 8px;
          border: 1px solid #dcdcdc;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.3s linear;
          }

          button {
            position: absolute;
            padding: 0;
            top: -8px;
            right: -8px;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border: 1px solid transparent;
            border-radius: 100%;
            transition: all 0.3s linear;
            box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.12);

            &:hover {
              border: 0.5px solid #77b6f4;
            }
          }
        }
      }

      &_button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 24px;
        margin-top: 60px;
      }
    }
  }

  &_register-note {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s linear;
    z-index: 10;

    &.open {
      opacity: 1;
      pointer-events: auto;
    }

    &_overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(41, 55, 72, 0.6);
      z-index: 10;
    }

    &_body {
      position: relative;
      z-index: 11;
      max-width: 912px;
      width: 100%;
      padding: 40px;
      border-radius: 20px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      &_title {
        color: #231f20;

        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        /* 150% */
      }

      &_content {
        margin-top: 32px;

        label {
          color: #514b4d;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;

          /* 150% */
          span {
            color: #f43258;
          }
        }

        textarea {
          width: 100%;
          height: 288px;
          padding: 16px;
          border-radius: 8px;
          border: 1px solid #adadad;
          background: #fff;
          margin-top: 8px;
          outline: none;
          font-size: 14px;
          font-weight: 500;
        }
      }

      &_button-wrap {
        display: flex;
        align-items: center;
        gap: 24px;
        justify-content: flex-end;
        margin-top: 60px;
      }
    }
  }

  &_note-item {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s linear;
    z-index: 10;

    &.open {
      opacity: 1;
      pointer-events: auto;
    }

    &_overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(41, 55, 72, 0.6);
      z-index: 10;
    }

    &_body {
      position: relative;
      z-index: 11;
      max-width: 912px;
      width: 100%;
      padding: 40px;
      border-radius: 20px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      &_title {
        color: #231f20;

        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        /* 150% */
      }

      &_info {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 32px;

        &_name {
          display: flex;
          align-items: center;
          gap: 4px;

          .include-img {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          p {
            color: #514b4d;

            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            /* 160% */
          }
        }

        &_time {
          color: #514b4d;

          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          /* 160% */
        }
      }

      &_content {
        margin-top: 12px;

        label {
          color: #514b4d;

          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;

          /* 150% */
          span {
            color: #f43258;
          }
        }

        textarea {
          width: 100%;
          height: 288px;
          padding: 16px;
          border-radius: 8px;
          border: 1px solid #adadad;
          background: #fff;
          margin-top: 8px;
          outline: none;
          color: #514b4d;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;

          &:hover {
            border: 1px solid #adadad;
            outline: none;
            box-shadow: none;
          }
        }
      }

      &_button-wrap {
        display: flex;
        align-items: center;
        gap: 24px;
        justify-content: flex-end;
        margin-top: 60px;
      }
    }
  }

  .can-change {
    background-color: #ffffff !important;
    border: 1px solid #adadad !important;
  }

  &__notify-modal {
    .ant-modal-content {
      min-height: 180px;
      border-radius: 20px;
      padding: 40px;
    }

    .ant-modal-body {
      padding: 0 20px;

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }
}

.remove-image-modal {
  display: flex;
  justify-content: center;
  width: 384px !important;

  .ant-modal-content {
    padding: 24px 32px !important;
    max-width: 384px;
  }

  .ant-modal-body {
    margin-top: 0px;
    margin-bottom: 0 !important;
  }

  .ant-modal-footer {
    .m-t-36 {
      margin-top: 24px !important;
    }
  }
}

.upload_by_staff {
  border: 1px solid #7e7e7e !important;
  cursor: not-allowed !important;

  p {
    color: #7e7e7e !important;
  }

  svg > path {
    stroke: #7e7e7e;
  }
}

.upload_by_staff:hover {
  cursor: default;
  background: unset !important;

  svg > path {
    stroke: #7e7e7e !important;
  }
}

.confirm-models {
  width: 384px !important;
  top: 40%;
  .bg-DCDCDC {
    background-color: #dcdcdc;
  }
  .border-9D9D9D {
    border: 2px solid #9d9d9d;
  }
}

.modal-confirm-wrap {
  .ant-modal-header {
    margin-bottom: 24px;
  }
  .ant-modal-content {
    width: 464px;
    height: 164px;
    padding: 24px 32px !important;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;

    .title {
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: center;
      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #514b4d;
        margin-left: 10px;
      }
    }

    .btn-wrap {
      display: flex;
      justify-content: center;
      gap: 24px;
    }

    .custom-button__cancel {
      border: 2px solid #9d9d9d;
      width: 148px !important;
      height: 44px !important;
      background: #dcdcdc;
      font-weight: 600;
      span {
        color: #514b4d;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      &:hover {
        background: #f2f2f2 !important;
        color: #7e7e7e !important;
        border-color: #9d9d9d !important;
      }
    }

    .custom-button__submit {
      border: 2px solid #1c86ed;
      width: 148px !important;
      height: 44px !important;
      background: #1c86ed;
      font-weight: 600;
      span {
        color: #fff;
        font-weight: 600;
        font-size: 16px;
      }

      &:hover {
        border: unset;
        border-color: #1c86ed;
      }
    }
  }

  .ant-modal-footer {
    display: none !important;
    .ant-btn {
      &:nth-child(1) {
        &:hover {
          background: #f2f2f2 !important;

          span {
            color: #7e7e7e;
          }
        }
      }
    }
  }
}

.label-has-input {
  width: unset;
  word-break: keep-all;
}

.label-has-input {
  width: unset;
  word-break: keep-all;
}

.spin-media {
  &.ant-spin {
    max-height: unset !important;
    top: 0 !important;
  }
}

.popup-feedback {
  .ant-popover-content {
    width: 100px;
    height: 56px;
  }

  .ant-popover-inner {
    padding: unset !important;
    border-radius: 4px !important;

    &-content {
      width: 100%;

      .note-dropdown {
        &-item {
          display: flex;
          gap: 8px;
          padding: 6px 8px;
          align-items: end;
          cursor: pointer;
          border-radius: 4px;

          &:hover {
            background-color: #1c86ed;

            .text {
              color: #fff;
            }

            .icon svg {
              stroke: #fff !important;
              fill: #fff;
            }
          }

          .text {
            font-size: 10px;
            font-weight: 500;
            line-height: 16px;
            text-align: left;
            color: #514b4d;
            margin: 0;
          }

          .icon {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
