.custom-select {
  &__container {
    width: 100%;

    .ant-select-selector {
      // border: 1px solid #adadad !important;
      border-radius: 8px;
      padding: 0 16px !important;
    }

    .ant-select-selection-item {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #514b4d;
    }
  }

  &__form-container {
    .ant-select-status-error {
      .ant-select-selector {
        border: 1px solid #ff4d4f !important;
      }
    }
  }
}
