.custom-ant-button {
  min-width: 148px;
  height: 44px;
  border-radius: 8px;
}

.custom-ant-button:not(.btn-disable):hover {
  background: #77b6f4 !important;
}

.custom-ant-sencond-button {
  min-width: 280px;
  height: 44px;
  border-radius: 32px;
}

.custom-ant-sencond-button:not(.btn-disable):hover {
  border: 2px solid #77b6f4 !important;

  span {
    color: #77b6f4 !important;
  }
  svg {
    opacity: 0.6;
  }
}

.custom-ant-normal-button {
  width: 148px;
  height: 44px;
  border-radius: 8px;
}

.custom-ant-normal-button:hover {
  border-color: #77b6f4 !important;
  color: #77b6f4 !important;
}

.button-detail {
  &__container {
    width: 76px;
    height: 32px;
    padding: 7px 20px;
    border-radius: 16px;
    border: 1px solid #1c86ed;
    align-items: unset;
    //justify-content: space-between;
    svg {
      rect {
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      path {
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
    &:hover {
      background: #1c86ed !important;
      span {
        color: #ffffff;
      }
      svg {
        rect {
          fill: #ffffff;
          stroke: #ffffff;
        }
        path {
          stroke: #1c86ed;
        }
      }
    }
  }

  &__content {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    margin-bottom: 0 !important;
    color: #1c86ed;
    white-space: pre-line;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.download-button {
  span {
    font-weight: 600;
    font-size: 10px;
    color: #1c86ed;
  }
}
