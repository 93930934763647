//color
.color-primary {
  color: $color_primary !important;
}

.color-title {
  color: $text_title_color !important;
}

.color-sub-title {
  color: $text_title_sub_color !important;
}

.color-cancel {
  color: $bg_cancel !important;
}

.color-sub-cancel {
  color: $color_sub_cancel !important;
}

.color-tex-btn-disable {
  color: $bg_cancel !important;
}

.color-btn-disable {
  color: #7e7e7e;
}

//bg
.bg-cus-primary {
  background-color: $bg_primary !important;
}

.bg-cancel {
  background-color: $bg_cancel !important;
}

.bg-sub-cancel {
  background-color: $bg_sub_cancel !important;
}

.bg-disable {
  background-color: $bg_disable !important;
}
//border
.border-cus-primary {
  border-color: $color_primary !important;
}
