.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.gx-popover-horizantal .ant-popover-inner {
  padding: 16px 8px;
}

.gx-popover-horizantal .ant-popover-arrow,
.ant-dropdown .ant-dropdown-arrow {
  display: none;
}

.text-pre-line {
  white-space: pre-line;
}

.break-word {
  word-break: break-word;
}

@for $i from 1 through 2 {
  .border-9D9D9D-#{$i} {
    border: #{$i}px solid #9d9d9d !important;
  }
  .border-1C86ED-#{$i} {
    border: #{$i}px solid #1c86ed !important;
  }

  .text-#{$i}-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: #{$i};
    line-clamp: #{$i};
    -webkit-box-orient: vertical;
  }
}

@for $i from 10 through 48 {
  .font-size-#{$i} {
    font-size: #{$i}px !important;
  }
}

@for $i from 0 through 700 {
  .height-#{$i} {
    height: #{$i}px !important;
  }
  .width-#{$i} {
    width: #{$i}px !important;
  }
}
@for $i from 0 through 100 {
  .m-t-#{$i} {
    margin-top: #{$i}px !important;
  }
  .m-r-#{$i} {
    margin-right: #{$i}px !important;
  }
  .m-b-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .m-l-#{$i} {
    margin-left: #{$i}px !important;
  }
  .m-#{$i} {
    margin: #{$i}px !important;
  }
  .m-t-b-#{$i} {
    margin-top: #{$i}px !important;
    margin-bottom: #{$i}px !important;
  }
  .m-l-r-#{$i} {
    margin-left: #{$i}px !important;
    margin-right: #{$i}px !important;
  }
}
@for $i from 0 through 150 {
  .gap-#{$i} {
    gap: #{$i}px !important;
  }
  .p-t-#{$i} {
    padding-top: #{$i}px !important;
  }
  .p-r-#{$i} {
    padding-right: #{$i}px !important;
  }
  .p-b-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  .p-l-#{$i} {
    padding-left: #{$i}px !important;
  }
  .p-#{$i} {
    padding: #{$i}px !important;
  }
}

.ebay__scroll-bar-custom-horizon {
  .ant-table-content {
    scrollbar-color: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-corner,
    &::-webkit-scrollbar-track {
      background-color: #dcdcdc;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #adadad;
      background-clip: padding-box;
      border: 2px solid transparent;
      border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgb(112, 112, 112);
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: rgb(128, 128, 128);
    }

    /* Buttons */
    &::-webkit-scrollbar-button:single-button {
      background-color: #dcdcdc;
      display: block;
      background-size: 8px;
      background-repeat: no-repeat;
    }

    /* Up */
    &::-webkit-scrollbar-button:single-button:vertical:decrement {
      height: 10px;
      width: 10px;
      background-position: center 4px;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
    }

    &::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");
    }

    &::-webkit-scrollbar-button:single-button:vertical:decrement:active {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='50,00 0,50 100,50'/></svg>");
    }

    /* Down */
    &::-webkit-scrollbar-button:single-button:vertical:increment {
      height: 10px;
      width: 10px;
      background-position: center 2px;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
    }

    &::-webkit-scrollbar-button:single-button:vertical:increment:hover {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
    }

    ::-webkit-scrollbar-button:single-button:vertical:increment:active {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 100,0 50,50'/></svg>");
    }

    /* Left */
    &::-webkit-scrollbar-button:single-button:horizontal:decrement {
      height: 7px;
      width: 14px;
      background-position: 5px 1px;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>");
    }

    &::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
    }

    &::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
    }

    /* Right */
    &::-webkit-scrollbar-button:single-button:horizontal:increment {
      height: 7px;
      width: 17px;
      background-position: 5px 1px;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
    }

    &::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>");
    }

    &::-webkit-scrollbar-button:single-button:horizontal:increment:active {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
    }
  }
}
.ebay__scroll-bar-custom {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-corner,
  &::-webkit-scrollbar-track {
    background-color: #dcdcdc;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #adadad;
    background-clip: padding-box;
    border: 2px solid transparent;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(112, 112, 112);
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: rgb(128, 128, 128);
  }

  /* Buttons */
  &::-webkit-scrollbar-button:single-button {
    background-color: #dcdcdc;
    display: block;
    background-size: 8px;
    background-repeat: no-repeat;
  }

  /* Up */
  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    height: 10px;
    width: 10px;
    background-position: center 4px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='50,00 0,50 100,50'/></svg>");
  }

  /* Down */
  &::-webkit-scrollbar-button:single-button:vertical:increment {
    height: 10px;
    width: 10px;
    background-position: center 2px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:vertical:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
  }

  ::-webkit-scrollbar-button:single-button:vertical:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 100,0 50,50'/></svg>");
  }

  /* Left */
  &::-webkit-scrollbar-button:single-button:horizontal:decrement {
    height: 7px;
    width: 14px;
    background-position: 5px 1px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
  }

  /* Right */
  &::-webkit-scrollbar-button:single-button:horizontal:increment {
    height: 7px;
    width: 17px;
    background-position: 5px 1px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:horizontal:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
  }

  .ant-table-container {
    scrollbar-color: auto;
    .ant-table-body {
      scrollbar-color: auto;
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }

      &::-webkit-scrollbar-corner,
      &::-webkit-scrollbar-track {
        background-color: #dcdcdc;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #adadad;
        background-clip: padding-box;
        border: 2px solid transparent;
        border-radius: 12px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: rgb(112, 112, 112);
      }

      &::-webkit-scrollbar-thumb:active {
        background-color: rgb(128, 128, 128);
      }

      /* Buttons */
      &::-webkit-scrollbar-button:single-button {
        background-color: #dcdcdc;
        display: block;
        background-size: 8px;
        background-repeat: no-repeat;
      }

      /* Up */
      &::-webkit-scrollbar-button:single-button:vertical:decrement {
        height: 10px;
        width: 10px;
        background-position: center 4px;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
      }

      &::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");
      }

      &::-webkit-scrollbar-button:single-button:vertical:decrement:active {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='50,00 0,50 100,50'/></svg>");
      }

      /* Down */
      &::-webkit-scrollbar-button:single-button:vertical:increment {
        height: 10px;
        width: 10px;
        background-position: center 2px;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
      }

      &::-webkit-scrollbar-button:single-button:vertical:increment:hover {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
      }

      ::-webkit-scrollbar-button:single-button:vertical:increment:active {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 100,0 50,50'/></svg>");
      }

      /* Left */
      &::-webkit-scrollbar-button:single-button:horizontal:decrement {
        height: 7px;
        width: 14px;
        background-position: 5px 1px;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>");
      }

      &::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
      }

      &::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
      }

      /* Right */
      &::-webkit-scrollbar-button:single-button:horizontal:increment {
        height: 7px;
        width: 17px;
        background-position: 5px 1px;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
      }

      &::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>");
      }

      &::-webkit-scrollbar-button:single-button:horizontal:increment:active {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
      }
    }
  }
}

::placeholder {
  color: #7e7e7e !important;
}

.no-decoration {
  text-decoration: none !important;
  cursor: unset !important;
}
