.list-notify {
  &-title {
    //margin-left: 16px;
    color: #231f20;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 24px;
  }
  &-search-button {
    display: flex;
    &-search {
      margin-left: 16px;
      margin-bottom: 24px;
    }
    &-button {
      margin-left: auto;
    }
  }
  .ant-custom-search-input {
    .ant-input {
      padding-left: 8px;
    }
  }
}

.ant-tooltip-inner {
  min-width: 56px !important;
  min-height: 34px !important;
  padding: 8px 16px 8px 16px !important;
  gap: 10px !important;
  border-radius: 10px !important;
  opacity: 0px !important;
  background-color: #ffffff !important;
  color: #514b4d !important;
}

.ant-table {
  .ant-table-cell {
    padding: 0 6px !important;
    height: 56px;
  }
}
