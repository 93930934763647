.view-status {
  &__container {
    height: 32px;
    width: 120px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 12px;
      font-weight: 600;
      line-height: 1;
      text-align: center;
      white-space: pre-line;
    }
  }

  //status
  &__not-shipped {
    background-color: #f7b02f29;

    span {
      color: #ffb021;
    }
  }

  &__in-transit {
    background-color: #80008029;

    span {
      color: #800080;
    }
  }

  &__delivered,
  &__waiting-storage,
  &__waiting-upload-image {
    background-color: #01c3c629;

    span {
      color: #01c3c6;
    }
  }

  &__in-storage {
    background-color: #14815829;

    span {
      color: #148158;
    }
  }

  &__shipping-preparing,
  &__shipping-preparing-done,
  &__shipped-back {
    background-color: #ff634729;

    span {
      color: #ff6347;
    }
  }

  &__shipped {
    background-color: #d2e7fb;

    span {
      color: #1c86ed;
    }
  }

  &__new-disposal-request,
  &__price-inputed {
    background-color: #ffe0fa;

    span {
      color: #d52198;
    }
  }

  &__disposal-done,
  &__canceled,
  &__TEMPORARY_CANCELED {
    background-color: #ffe0e0;

    span {
      color: #f43258;
    }
  }
}
