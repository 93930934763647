.custom {
  &__date-picker {
    border: unset !important;
    box-shadow: unset !important;
    padding: 0 !important;
    cursor: pointer;

    &-panel {
      .ant-picker-month-panel,
      .ant-picker-year-panel,
      .ant-picker-decade-panel {
        .ant-picker-body {
          padding-top: 20px !important;
        }
      }

      .ant-picker-date-panel {
        width: 332px !important;
      }

      .ant-picker-header {
        position: relative;
        justify-content: end;
        height: 24px;
        border-bottom: unset !important;
        margin-top: 20px;
        padding: 0 20px;

        button {
          height: 24px !important;
          line-height: 1 !important;

          span {
            &::before {
              border-block-start-width: 2px !important;
              border-inline-start-width: 2px !important;
            }
          }
        }

        .ant-picker-header-super-prev-btn,
        .ant-picker-header-super-next-btn {
          display: none;
        }

        .ant-picker-header-view {
          height: 24px;
          position: absolute;
          left: 20px;
          top: 2px;
          //display: flex;
          //flex-direction: row-reverse;
          //gap: 5px;

          button {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-align: left;
            color: #293748;
            //margin: 0 !important;
          }
        }
      }

      .ant-picker-body {
        padding: 0 20px 20px !important;

        .ant-picker-content {
          thead {
            tr {
              th {
                font-size: 10px;
                font-weight: 600;
                line-height: 13.62px;
                text-align: center;
                color: #231f20;
              }
            }
          }

          tbody {
            tr {
              border-spacing: 0 !important;
            }

            .ant-picker-cell-inner {
              font-size: 10px;
              font-weight: 400;
              line-height: 13.62px;
              text-align: center;
              color: #dcdcdc !important;
              width: auto;
              height: auto;

              &:before {
                content: unset !important;
              }
            }

            .ant-picker-cell {
              padding: 0 !important;
              height: 42px;
              width: 42px;
              background-color: #f7f7f7;
              border: 1px solid #dcdcdc;

              &:hover {
                .ant-picker-cell-inner {
                  background: unset !important;
                }
              }

              &-today {
                &::before {
                  width: 100%;
                  height: 100%;
                  border: 1px solid #1677ff;
                }
              }

              &-in-view {
                background: #ffffff;

                .ant-picker-cell-inner {
                  color: #231f20 !important;
                }
              }

              &-selected {
                background: #514b4d !important;

                .ant-picker-cell-inner {
                  background: unset !important;
                  color: #ffffff !important;
                }
              }

              &-disabled {
                &::before {
                  height: 100% !important;
                  background: #00000033;
                }
              }
            }
          }
        }
      }
    }

    .ant-picker-input {
      input {
        width: 120px;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #adadad;
        padding: 10px;

        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #514b4d;
      }

      .ant-picker-suffix {
        margin-left: 8px !important;
      }
      .ant-picker-clear {
        .anticon svg {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}
