.ant-custom-tabs {
  .ant-tabs-tab {
    position: relative;
    padding: 9px 0;
    margin: 0 !important;
    min-width: 200px;
    border-bottom: 1px solid #7e7e7e;
    color: #7e7e7e !important;

    .ant-tabs-tab-btn {
      width: 172px;
      height: 32px;
      top: 2px;
      left: 4px;
      padding: 4px;
      gap: 10px;
      border-radius: 4px;
    }

    .ant-tabs-tab-btn,
    svg {
      color: #7e7e7e;
    }
  }
  .ant-tabs-tab:not(.ant-tabs-tab-active):hover {
    .ant-tabs-tab-btn {
      background-color: #f2f2f2;
    }
  }
  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #231f20 !important;
    }
  }
  .ant-tabs-ink-bar {
    background: #231f20 !important;
    height: 3px !important;
    border-radius: 2px;
  }
}
