.ant-custom-modal {
  border-radius: 20px;
  min-width: 912px;

  .ant-modal-content {
    padding: 40px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
  }

  .ant-modal-close {
    height: 20px;
    width: 20px;
    color: #514b4d;
  }
}
.staff-models {
  top: 84px;
  border-radius: 20px;
  min-width: 912px;
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
    background: #514b4d;
  }
  .ant-switch-inner {
    border: 1.5px solid #9d9d9d;
    background-color: #f2f2f2;
  }

  .ant-switch-handle::before {
    background-color: #514b4d;
  }

  .ant-switch-checked {
    .ant-switch-inner {
      border: 1.5px solid #1c86ed;
      background-color: #ffffff;
    }
    .ant-switch-handle::before {
      background-color: #1c86ed;
    }
  }
  .ant-radio-checked .ant-radio-inner {
    border: 2px solid #514b4d;
    background-color: white;
    ::after {
      transform: scale(0.375);
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      background: white;
    }
  }
  .ant-form-item {
    margin-bottom: 16px;
  }
  .ant-form-item-label > label {
    color: var(--Black-2, #514b4d);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .ant-modal-body {
    width: 608px !important;
  }
  .switch {
    text-align: right;
    .ant-form-item-row {
      flex-direction: unset !important;
      .ant-form-item-control {
        width: 50%;
      }
    }
  }

  .ant-modal-content {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .staff-management-title {
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: 700;
    font-size: 24px;
  }

  .centered-text {
    text-align: center;
    margin-bottom: 16px;
    color: #514b4d;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
    max-width: 528px;
  }

  .title-radio {
    margin-bottom: 20px;
  }

  .title-role {
    color: var(--Black-1, #231f20);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    margin-top: 44px;
    margin-bottom: 20px;
  }
}
.confirm-models {
  min-width: 630px;
  min-height: 236px;

  top: 250px !important;
  .ant-modal-content {
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
  .ant-modal-body {
    color: var(--Red, #f43258);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    text-align: center;
    margin-top: 16px;
    margin-bottom: 36px;
  }

  .ant-modal-title {
    text-align: center;
  }

  .ant-modal-footer {
    text-align: center;
  }
}

.confirm-delete-models {
  width: 384px !important;
  height: 140px !important;
  .center-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    text-align: center;
  }
  top: 250px !important;
  .ant-modal-content {
    padding: 24px 32px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
  .ant-modal-body {
    color: var(--Red, #f43258);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    text-align: center;
    margin-top: 16px;
    margin-bottom: 36px;
  }

  .ant-modal-title {
    text-align: center;
  }

  .ant-modal-footer {
    text-align: center;
  }
}
.storage-models {
  width: 630px !important;
  top: 38%;
  .bg-DCDCDC {
    background-color: #dcdcdc;
  }
  .border-9D9D9D {
    border: 2px solid #9d9d9d;
  }
  .ant-modal-content {
    padding: 40px 43px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ant-modal-body {
    margin: 20px 30px;
    padding: 0 18px;
  }
  &-text {
    color: #9d9d9d;
    font-size: 16px;
    font-weight: 500;
  }
  &-sub-text {
    color: #514b4d;
    font-weight: 700;
  }
}
.error-models {
  width: 384px !important;
  top: 40%;
  .bg-DCDCDC {
    background-color: #dcdcdc;
  }
  .border-9D9D9D {
    border: 2px solid #9d9d9d;
  }
}
.notification-models {
  .ant-modal-body {
    width: 100%;
  }
}
