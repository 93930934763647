.ant-custom-search-input {
  width: 400px;
  height: 40px;
  border-radius: 24px;
  border: 1.5px solid #adadad;
  font-weight: 500;
}

.ant-custom-input {
  height: 54px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #adadad;
  background: #ffff;
  color: #514b4d;
  font-weight: 500;
  font-size: 16px;

  input {
    font-size: 16px;
  }
}

.ant-custom-input-area {
  height: 54px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #adadad;
  background: #ffff;
  font-weight: 500;
  color: #514b4d;
  font-size: 16px;

  input {
    font-size: 16px;
  }
  .ant-input {
    font-weight: 500;
    color: #514b4d;
  }
}

textarea {
  resize: none !important;
}
