.ant-custom-form-wrap {
  .ant-form-item-required {
    flex-direction: row-reverse !important;
    gap: 8px;
  }
  label {
    color: var(--Black-2, #514b4d);
    font-size: 16px;
  }
  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }
}
