.custom-button {
  &__default {
    border-radius: 8px !important;
    font-size: 16px;
    height: 48px;
  }

  &__link {
    padding: 0 !important;
    margin: 0 !important;
    height: auto !important;
    color: #1c86ed !important;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    border-radius: unset !important;
  }

  &__cancel {
    background: #dcdcdc;
    color: #231f20;
    font-weight: 600;
    border: 2px solid #9d9d9d;

    &:hover,
    &:active,
    &:focus {
      background: #f2f2f2 !important;
      color: #231f20 !important;
      border: 2px solid #9d9d9d !important;
    }
  }

  &__primary {
    background: #1c86ed;
    color: #ffffff;
    font-weight: 600;
    border: unset;

    &:hover,
    &:active,
    &:focus {
      border: unset !important;
      background: #77b6f4 !important;
      color: #ffffff !important;
    }
  }

  &__text {
    background: transparent !important;
    // color: $text_header_modal_color;
    font-weight: 600;
    height: auto !important;
    font-size: 14px !important;
    padding: 0 !important;
    margin: 0 !important;

    &:hover,
    &:active,
    &:focus {
      background: transparent !important;
      // color: $primary_color !important;
    }
  }

  &__styled {
    .ant-btn {
      height: 46px;
      padding: 12px;
      // border: 1px solid $primary_color;
      // background: $primary_color;
      // color: $white-color;
      font-size: 16px !important;
      font-weight: 700 !important;
      line-height: 1;
    }

    &-disable {
      // background: $disable-color !important;
      // border-color: $disable-color !important;
    }
  }
}
.button-small {
  width: 148px !important;
  height: 44px !important;
}
