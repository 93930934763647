@import './styles/index.scss';
@import './styles/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: $font_primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: $font_primary !important;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-layout {
  background-color: #ffff;

  .layout-sider-small {
    min-width: 76px !important;
    max-width: 76px !important;
    width: 76px !important;

    .menu-icon-small {
      gap: 16px;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }

  .layout-sider-default {
    min-width: 200px !important;
    max-width: 200px !important;
    width: 200px !important;

    .menu-icon-small {
      svg {
        display: none !important;
      }
    }
  }
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.ant-input,
.ant-btn {
  font-family: $font_primary !important;
}

img {
  object-fit: contain;
}
