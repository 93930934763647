// .request-mangement {
//   :where(.css-dev-only-do-not-override-1qfezbu).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//     color: inherit;
//   }
//   padding: 40px 40px;
//   .ant-tabs-nav-list {
//     border-bottom: 1px solid #9d9d9d;
//   }
//   .ant-tabs-tab-active {
//     border-bottom: 2px solid black !important;
//   }
//   &__support {
//     position: fixed;
//     bottom: 0;
//     right: 0;
//     margin-right: 32px;
//     margin-bottom: 32px;
//   }

//   .border-avatar {
//     border-radius: 100px;
//     border: 2px solid rgba(28, 134, 237, 0.32);
//   }

//   .switch-true {
//     color: #514b4d;
//   }

//   .switch-false {
//     color: #adadad;
//   }

//   .color-514B4D {
//     color: #514b4d;
//   }
//   .bg-514B4D {
//     background-color: #514b4d;
//   }
//   &__table {
//     tr:nth-child(odd) {
//       background-color: #f7f7f7;
//     }
//     &-text {
//       font-family: 'Noto Sans JP', sans-serif;;
//       font-size: 14px;
//       font-style: normal;
//       font-weight: 500;
//       line-height: 22px;
//     }
//   }
// }

.request-management {
  &__col-copy {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__btn-copy {
    border: unset !important;
    background: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    outline: 0 !important;
  }
  &__status-sku {
    display: flex;
    gap: 7px;
    align-items: center;
  }
  &__table-empty {
    border: 1px solid #dcdcdc;
    position: sticky;
    overflow: hidden;
    left: 0;
  }
  &__table-row-data {
    border-bottom: 1px solid #dcdcdc;
  }
  &__product-bar-code {
    .ant-input-affix-wrapper {
      width: 468px;
      height: 40px;
      border: 1px solid #adadad;
      padding: 8px 19px 8px 16px;
      border-radius: 8px;

      &-focused {
        border-color: #1c86ed;
      }
      input {
        font-size: 16px;
      }
    }
    .ant-form-item-required {
      &:before {
        content: unset !important;
      }
    }
  }
  &__popup-shippng {
    padding: 0 !important;
    border: unset !important;
    box-shadow: unset !important;
    background: transparent !important;
    outline: 0 !important;
  }

  &__product-info {
    p {
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      color: #514b4d;
      margin-bottom: 16px !important;
    }
  }

  &__judgement {
    &-container {
      display: flex;
      gap: 8px;
    }

    &-title {
      width: 96px;
      height: 24px;

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #7e7e7e;

        span {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: #514b4d;
        }
      }
    }

    &-description {
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #514b4d;
      }
    }
  }

  &__form-request-shiping {
    display: flex;
    align-items: end;
    gap: 20px;
  }

  &__require {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #f43258;
    margin-left: 8px;
  }

  &__row-container {
    display: flex;
    gap: 8px;
    padding-bottom: 12px;
  }

  &__gird-container {
    border-bottom: 1px solid #7e7e7e;
    margin-bottom: 24px;
    padding-bottom: 12px;
  }

  &__col {
    &-left {
      &-container {
        width: 96px;
        position: relative;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #7e7e7e;

        &:before {
          content: ':';
          position: absolute;
          right: 0;
          color: #7e7e7e;
        }
      }
    }

    &-right {
      &-container {
        width: auto;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #514b4d;
      }
    }
  }

  &__btn {
    &-container {
      display: flex;
      gap: 24px;
      justify-content: end;
    }

    &-confirm {
      width: 120px;
      height: 40px;
      background: #fff;
      border: 2px solid #1c86ed !important;

      span {
        color: #1c86ed !important;
        line-height: 3;
        font-weight: 600;
      }

      &:hover {
        border-color: #77b6f4 !important;
        background-color: #fff !important;

        span {
          color: #77b6f4 !important;
        }
      }
      &:active {
        background: #fff !important;
        border: 2px solid #1c86ed !important;
      }
      &:focus {
        background: #fff !important;
        border: 2px solid #1c86ed !important;
      }
    }

    &-print {
      width: 148px !important;
      height: 44px !important;
      background-color: #fff;
      border: 2px solid #1c86ed !important;

      p {
        display: flex;
        gap: 8px;
        color: #1c86ed !important;
        line-height: 3;
      }

      span {
        color: #1c86ed !important;
      }

      &:hover {
        border-color: #77b6f4 !important;
        background-color: #fff !important;

        p {
          color: #77b6f4 !important;
        }

        span {
          svg {
            path {
              stroke: #77b6f4 !important;
            }
          }
        }
      }

      &:disabled {
        background-color: #ffffff !important;
        border-color: #dcdcdc !important;

        p {
          color: #7e7e7e !important;
        }

        span {
          svg {
            path {
              stroke: #7e7e7e !important;
            }
          }
        }
      }
    }

    &-cancel {
      width: 148px !important;
      height: 44px !important;
      background-color: #1c86ed;
      border: 1px solid #1c86ed;

      span {
        color: #ffffff;
      }

      &:disabled {
        background-color: #dcdcdc !important;
        border-color: #dcdcdc !important;

        span {
          color: #7e7e7e;
          line-height: 3;
        }
      }
    }
  }

  &__modal-shipping {
    width: 912px !important;

    .ant-modal-content {
      min-height: 556px;
      padding: 40px 152px;
      border-radius: 20px;

      .ant-modal-header {
        .ant-modal-title {
          font-size: 24px !important;
          font-weight: 700;
          line-height: 36px;
          text-align: center;
          color: #231f20;
        }

        margin-bottom: 32px;
      }

      .ant-modal-body {
        border-bottom: 1px solid #7e7e7e;
        padding-bottom: 32px;
        margin-bottom: 32px;
      }
    }
  }

  &__table {
    margin-top: 24px;

    &-pagination {
      &-container {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        background: #f2f2f2;
        border-left: 1px solid #dcdcdc;
        border-right: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;

        .ant-pagination {
          .ant-pagination-item {
            background: #ffffff;
            border: 1px solid #514b4d;
            width: 32px;
            height: 32px;
            border-radius: 2px;
            display: inline-block;

            a {
              font-size: 14px;
              font-weight: 500;
              text-align: center;
              color: #514b4d;
            }

            &-active {
              background: #514b4d;

              a {
                color: #ffffff;
              }
            }

            &-link {
              background: #ffffff;
              border: 1px solid #eef0f4;
              width: 32px;
              height: 32px;
              border-radius: 2px;
            }
          }

          &-jump {
            &-next,
            &-prev {
              a {
                display: inline-block;
                //background: unset !important;
                .ant-pagination-item-ellipsis {
                  color: #514b4d !important;
                }
              }
            }
          }

          .ant-pagination-options {
            .ant-select {
              .ant-select-arrow {
                font-size: 17px;
                color: #514b4d;
              }

              &-selector {
                width: 88px;
                height: 32px;
                border: 1px solid #adadad;
                border-radius: 2px;
                padding: 5px 10px;

                .ant-select-selection-item {
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 24px;
                  text-align: left;
                  color: #514b4d;
                }
              }
            }
          }
        }
      }
    }

    tr:nth-child(even) {
      .ant-table-cell {
        background-color: #ffffff !important;
      }
    }

    tr:nth-child(odd) {
      .ant-table-cell {
        background-color: #f7f7f7 !important;

        p {
          color: #231f20 !important;
        }
      }
    }

    .ant-table-container {
      .ant-table-content {
        border: 1px solid #dcdcdc;
      }

      .ant-table-tbody {
        .ant-table-cell {
          padding: 3.5px 15px;
        }
      }
    }

    &-text {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 0;
    }
  }

  &__search-input {
    .ant-input {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #514b4d !important;

      &::placeholder {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #adadad !important;
      }
    }
  }

  &_title {
    color: #231f20;
    /* BOLD/28 */
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 142.857% */
    margin-bottom: 24px;
  }

  // .width {
  //   &_administrator {
  //     width: calc(100% / 6);
  //   }

  //   &_staff {
  //     width: calc(100% / 5);
  //   }

  //   &_operation {
  //     width: calc(100% / 5);
  //   }
  // }

  &_tab-title {
    display: flex;
    align-items: end;
    width: 100%;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    &_item {
      display: flex;
      align-items: center;
      gap: 8px;
      position: relative;
      padding: 2px 4px 6px;
      cursor: pointer;
      flex: 1;

      &-hover {
        width: 100%;
        padding: 4px;
        position: relative;

        &:hover {
          background: #f2f2f2;
          border-radius: 4px;
        }
      }

      &_quantity {
        position: absolute;
        top: 0;
        right: 4px;
        color: #514b4d;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1.5px;
        border-radius: 2px;
        background: #7e7e7e;
      }

      &_name {
        color: #7e7e7e;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        margin-bottom: 0;
      }

      &_quantity {
        position: absolute;
        top: 4px;
        right: 10px;
        color: #514b4d;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
      }

      svg {
        @media (max-width: 1600px) {
          width: 16px;
          height: 16px;
        }
      }

      &.active {
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          border-radius: 2px;
          background: #231f20;
        }

        svg {
          path {
            fill: #231f20;
          }
        }

        .request-management_tab-title_item_name {
          color: #231f20;
        }
      }
    }
  }

  &_filter {
    display: flex;
    align-items: center;
    gap: 80px;
    margin-top: 16px;

    &_date-wrap {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-left: -80px !important;

      p {
        color: #514b4d;
        text-align: justify;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin: 0;
      }

      &_date {
        display: flex;
        align-items: center;
        gap: 4px;

        &_text {
          color: var(--Black-2, #514b4d);
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }

        &_item {
          display: flex;
          align-items: center;
          gap: 7px;

          input {
            width: 120px;
            height: 40px;
            border: 1.5px solid #adadad;
            border-radius: 10px;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-align: center;

            &::-webkit-calendar-picker-indicator {
              display: none;
            }
          }

          button {
            background: transparent;
            padding: 0;
            border: none;
          }
        }

        span {
          color: #514b4d;
          text-align: justify;
          /* MEDIUM/20 */
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
          /* 160% */
        }
      }
    }

    &_checkbox {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-left: -48px;

      p {
        margin-bottom: 0;
        color: #514b4d;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
    }

    &_search {
      position: relative;
      margin-left: 15px;

      input {
        display: flex;
        width: 400px;
        height: 40px;
        padding: 8px 20px 8px 48px;
        align-items: center;
        border-radius: 24px;
        border: 1.5px solid var(--Black-4, #adadad);
        background: var(--White-1, #fff);
        outline: none;
        color: var(--Black-2, #514b4d);
        /* MEDIUM/16 */
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;

        /* 150% */
        @media (max-width: 1700px) {
          width: 360px;
        }

        &::placeholder {
          color: var(--Black-4, #adadad);
          /* MEDIUM/16 */
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          /* 150% */
        }
      }

      button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
        width: 20px;
        height: 20px;
        border: none;
        cursor: pointer;
        padding: 0;
        background: transparent;
      }
    }
  }

  &_table {
    margin-top: 24px;

    // tr {
    //   &:nth-child(2n + 1) {
    //     background: #f7f7f7;
    //   }
    // }

    &_1 {
      &_wrap {
        .ant-table-header {
          border-radius: unset !important;
        }
        .ant-table-thead {
          .ant-table-cell {
            height: 48px;
            background-color: #eef0f4 !important;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            color: #514b4d;
            border-start-start-radius: unset !important;
            border-start-end-radius: unset !important;
            border-bottom: 2px solid #adadad;

            &-scrollbar {
              box-shadow: unset !important;
            }

            &:before {
              content: unset !important;
            }
          }
        }
        // max-height: 800px;
        width: 100%;
        overflow-x: auto;
        border: 1px solid #dcdcdc;
        .ant-table-tbody {
          .ant-table-row {
            .ant-table-cell {
              border-bottom: 1px solid #dcdcdc;
              &.col {
                &_1 {
                  min-width: 180px !important;
                  max-width: 180px !important;
                }

                &_2 {
                  min-width: 200px;
                  max-width: 200px;
                }

                &_3 {
                  min-width: 430px;
                  max-width: 430px;
                }

                &_4 {
                  min-width: 228px;
                  max-width: 228px;
                }

                &_5 {
                  min-width: 160px;
                }

                &_6 {
                  min-width: 168px;
                }

                &_7 {
                  &_1 {
                    min-width: 160px;
                    max-width: 160px;
                  }

                  &_2 {
                    min-width: 160px;
                    max-width: 160px;
                  }

                  &_3 {
                    min-width: 160px;
                    max-width: 160px;
                  }

                  &_4 {
                    min-width: 160px;
                    max-width: 160px;
                  }

                  &_5 {
                    min-width: 160px;
                    max-width: 160px;
                  }
                }

                &_8 {
                  min-width: 426px;
                  max-width: 426px;
                  p {
                    word-break: break-word;
                  }
                }

                &_9 {
                  min-width: 100px;
                  max-width: 100px;
                  text-align: center;
                }
                &_10 {
                  min-width: 100px;
                  max-width: 100px;
                }
                &_11 {
                  min-width: 32px;
                  max-width: 32px;
                  text-align: center;
                }
              }
            }
          }
        }
      }

      table {
        width: 1366px;
        min-width: 100%;
        border: 1.5px solid #dcdcdc;
        background: #fff;
        overflow: auto;
        border-bottom: none;

        tr {
          &:nth-child(1) {
            position: relative;
            background: #eef0f4;

            &::before {
              display: none;
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background: #adadad;
            }
          }

          th {
            color: #514b4d;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
            height: 48px;
            padding: 11px 12px;
            border-bottom: 2px solid #adadad;
            // &:nth-child(1) {
            //   min-width: 180px;
            // }
            // &:nth-child(2) {
            //   min-width: 180px;
            // }
            // &:nth-child(3) {
            //   min-width: 446px;
            // }
            // &:nth-child(4) {
            //   min-width: 228px;
            // }
            // &:nth-child(5) {
            //   min-width: 168px;
            // }
            // &:nth-child(6) {
            //   min-width: 446px;
            // }
          }

          td {
            color: #514b4d;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
            padding: 6px 12px;
            height: 100%;
            //border-bottom: 1px solid #dcdcdc;
            &:nth-child(1) {
              // min-width: 180px;
              & > div {
                //display: flex;
                //align-items: center;
                //gap: 8px;

                //button {
                //  padding: 0;
                //  background: transparent;
                //  border: none;
                //}

                p {
                  margin-bottom: 0;
                }
              }
            }

            &:nth-child(2) {
              // min-width: 180px;
              & > div {
                p {
                  margin-bottom: 0;
                }

                span {
                  font-size: 12px;
                  line-height: 18px;
                }
              }
            }

            &:nth-child(3) {
              // min-width: 446px;
              & > div {
                //display: flex;
                //align-items: center;
                //gap: 16px;

                button {
                  padding: 0;
                  background: transparent;
                  border: none;
                }

                p {
                  margin-bottom: 0;
                }
              }
            }

            &:nth-child(4) {
              // min-width: 228px;
              p {
                margin-bottom: 0;
              }
            }

            &:nth-child(5) {
              // min-width: 160px;
              p {
                margin-bottom: 0;
              }
            }

            &:nth-child(6) {
              // min-width: 446px;
              p {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    &_2 {
      &_wrap {
        max-height: 800px;
        width: 100%;
        overflow: auto;
      }

      table {
        width: 100%;
        border: 1.5px solid #dcdcdc;
        background: #fff;
        overflow: auto;
        border-bottom: none;

        tr {
          &:nth-child(1) {
            position: relative;
            background: #eef0f4;

            &::before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background: #adadad;
            }
          }

          th {
            color: #514b4d;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
            height: 48px;
            padding: 11px 12px;
            // &:nth-child(1) {
            //   min-width: 180px;
            // }
            // &:nth-child(2) {
            //   min-width: 180px;
            // }
            // &:nth-child(3) {
            //   min-width: 426px;
            // }
            // &:nth-child(4) {
            //   min-width: 228px;
            // }
            // &:nth-child(5) {
            //   min-width: 120px;
            // }
            // &:nth-child(6) {
            //   min-width: 426px;
            // }
            // &:nth-child(7) {
            //   min-width: 80px;
            // }
          }

          td {
            color: #514b4d;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
            padding: 6px 12px;
            height: 100%;

            &:nth-child(1) {
              // min-width: 180px;
              & > div {
                display: flex;
                align-items: center;
                gap: 8px;

                button {
                  padding: 0;
                  background: transparent;
                  border: none;
                }

                p {
                  margin-bottom: 0;
                }
              }
            }

            &:nth-child(2) {
              // min-width: 180px;
              & > div {
                p {
                  margin-bottom: 0;
                }

                span {
                  font-size: 12px;
                  line-height: 18px;
                }
              }
            }

            &:nth-child(3) {
              // min-width: 426px;
              & > div {
                display: flex;
                align-items: center;
                gap: 16px;

                button {
                  padding: 0;
                  background: transparent;
                  border: none;
                }

                p {
                  margin-bottom: 0;
                }
              }
            }

            &:nth-child(4) {
              // min-width: 228px;
              p {
                margin-bottom: 0;
              }
            }

            &:nth-child(5) {
              // min-width: 120px;
              p {
                margin-bottom: 0;
              }
            }

            &:nth-child(6) {
              // min-width: 426px;
              p {
                margin-bottom: 0;
              }
            }

            &:nth-child(7) {
              // min-width: 80px;
              button {
                padding: 0;
                background: transparent;
                border: none;
              }
            }
          }
        }
      }
    }

    &_5 {
      &_wrap {
        max-height: 800px;
        width: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background: #adadad;
          border-radius: 12px;
        }

        &::-webkit-scrollbar-track {
          background: #dcdcdc;
        }
      }

      table {
        width: 100%;
        border: 1.5px solid #dcdcdc;
        background: #fff;
        overflow: auto;
        border-bottom: none;

        tr {
          &:nth-child(1) {
            position: relative;
            background: #eef0f4;

            &::before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background: #adadad;
            }
          }

          th {
            color: #514b4d;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
            height: 48px;
            padding: 11px 12px;
            // &:nth-child(1) {
            //   min-width: 180px;
            // }
            // &:nth-child(2) {
            //   min-width: 180px;
            // }
            // &:nth-child(3) {
            //   min-width: 740px;
            // }
            // &:nth-child(4) {
            //   min-width: 200px;
            // }
            // &:nth-child(5) {
            //   min-width: 220px;
            // }
            // &:nth-child(6) {
            //   min-width: 120px;
            // }
          }

          td {
            color: #514b4d;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
            padding: 6px 12px;
            height: 100%;

            &:nth-child(1) {
              // min-width: 180px;
              & > div {
                display: flex;
                align-items: center;
                gap: 8px;

                button {
                  padding: 0;
                  background: transparent;
                  border: none;
                }

                p {
                  margin-bottom: 0;
                }
              }
            }

            &:nth-child(2) {
              // min-width: 180px;
              & > div {
                p {
                  margin-bottom: 0;
                }

                span {
                  font-size: 12px;
                  line-height: 18px;
                }
              }
            }

            &:nth-child(3) {
              // min-width: 740px;
              & > div {
                display: flex;
                align-items: center;
                gap: 16px;

                p {
                  margin-bottom: 0;
                }
              }
            }

            &:nth-child(4) {
              // min-width: 200px;
              p {
                margin-bottom: 0;
              }
            }

            &:nth-child(5) {
              // min-width: 220px;
              p {
                margin-bottom: 0;
              }
            }

            &:nth-child(6) {
              // min-width: 120px;
              & > div {
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: center;
              }

              button {
                padding: 0;
                background: transparent;
                border: none;
              }
            }
          }
        }
      }
    }

    &_6 {
      &_wrap {
        max-height: 800px;
        width: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background: #adadad;
          border-radius: 12px;
        }

        &::-webkit-scrollbar-track {
          background: #dcdcdc;
        }
      }

      table {
        width: 100%;
        border: 1.5px solid #dcdcdc;
        background: #fff;
        overflow: auto;
        border-bottom: none;

        tr {
          &:nth-child(1) {
            position: relative;
            background: #eef0f4;

            &::before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background: #adadad;
            }
          }

          th {
            color: #514b4d;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
            height: 48px;
            padding: 11px 12px;
            // &:nth-child(1) {
            //   min-width: 180px;
            // }
            // &:nth-child(2) {
            //   min-width: 200px;
            // }
            // &:nth-child(3) {
            //   min-width: 652px;
            // }
            // &:nth-child(4) {
            //   min-width: 160px;
            // }
            // &:nth-child(5) {
            //   min-width: 160px;
            // }
            // &:nth-child(6) {
            //   min-width: 168px;
            // }
            // &:nth-child(7) {
            //   min-width: 120px;
            // }
          }

          td {
            color: #514b4d;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
            padding: 6px 12px;
            height: 100%;

            &:nth-child(1) {
              // min-width: 180px;
              & > div {
                display: flex;
                align-items: center;
                gap: 8px;

                button {
                  padding: 0;
                  background: transparent;
                  border: none;
                }

                p {
                  margin-bottom: 0;
                }
              }
            }

            &:nth-child(2) {
              // min-width: 200px;
              & > div {
                p {
                  margin-bottom: 0;
                }

                span {
                  font-size: 12px;
                  line-height: 18px;
                }
              }
            }

            &:nth-child(3) {
              // min-width: 652px;
              & > div {
                display: flex;
                align-items: center;
                gap: 16px;

                p {
                  margin-bottom: 0;
                }
              }
            }

            &:nth-child(4) {
              // min-width: 160px;
              p {
                margin-bottom: 0;
              }
            }

            &:nth-child(5) {
              // min-width: 160px;
            }

            &:nth-child(6) {
              // min-width: 168px;
              p {
                margin-bottom: 0;
              }
            }

            &:nth-child(7) {
              // min-width: 120px;
              & > div {
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: center;
              }

              button {
                padding: 0;
                background: transparent;
                border: none;
              }
            }
          }
        }
      }
    }

    &_pagination {
      padding: 10px;
      background: #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      &_choose {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        button {
          padding: 4px;
          border-radius: 2px;
          border: 1px solid #eef0f4;
          background: #fff;
          transition: all 0.3s linear;

          svg {
            width: 24px;
            height: 24px;

            path {
              transition: all 0.3s linear;
            }
          }

          &:hover {
            background: #514b4d;
          }

          &:hover svg path {
            stroke: #fff;
          }
        }

        span,
        a {
          color: #514b4d;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          padding: 1px 7px;
          border-radius: 2px;
          border: 1px solid #eef0f4;
          background: #fff;
          width: 34px;
          height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          transition: all 0.3s linear;

          &.active {
            color: #fff;
            background: #514b4d;
          }
        }

        a:hover {
          color: #fff;
          background: #514b4d;
        }
      }

      &_select {
        select {
          color: #514b4d;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
          display: flex;
          align-items: center;
          width: 88px;
          height: 32px;
          padding: 0 10px;
          border-radius: 2px;
          border: 0.5px solid #adadad;
          background: #fff;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-image: url(../../../../public/assets/icons/arow-select.svg) !important;
          background-position: right center !important;
          background-repeat: no-repeat !important;
          cursor: pointer;
        }
      }
    }
  }
}

.diff {
  height: 100%;
  padding-top: 6px;
}
